import * as el from 'element-ui'
import axios from "axios";
const errorCode={
    '401': '认证失败，无法访问系统资源',
    '403': '当前操作没有权限',
    '404': '访问资源不存在',
    'default': '系统未知错误，请反馈给管理员'
}


export async function lqDownload(url, data, filename) {
    let downloadLoadingInstance = el.Loading.service({ text: '正在下载数据，请稍候', background: 'rgba(0, 0, 0, 0.7)' })
    return axios.post(url, data, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'blob'
    }).then(async({data}) => {
        const isLogin = await blobValidate(data)
        if (isLogin) {
            const blob = new Blob([data])
            console.log('blob',blob)
           await saveAs(blob, filename)
        } else {
            const resText = await data.text()
            const rspObj = JSON.parse(resText)
            const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
            el.Message.error(errMsg)
        }
       return  downloadLoadingInstance.close()
    }).catch((r) => {
        console.error(r)
        el.Message.error('下载文件出现错误，请联系管理员！')
        return  downloadLoadingInstance.close()
    })
}

function tansParams(params, addTimestamp) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName]
        const part = encodeURIComponent(propName) + '='
        if (value !== null && value !== '' && typeof (value) !== 'undefined') {
            if (Array.isArray(value)) {
                value.forEach(v => {
                    if (v !== null && v !== '' && typeof (v) !== 'undefined') {
                        result += part + encodeURIComponent(v) + '&'
                    }
                })
            } else if (typeof value === 'object') {
                console.log('typaParam', 'object')
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value[key] !== '' && typeof (value[key]) !== 'undefined') {
                        const params = propName + '[' + key + ']'
                        const subPart = encodeURIComponent(params) + '='
                        result += subPart + encodeURIComponent(value[key]) + '&'
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + '&'
            }
        }
    }
    if (addTimestamp) {
        result += ('t=' + new Date().getTime())
    }
    return result
}


// 验证是否为blob格式
async function blobValidate(data) {
    try {
        const text = await data.text()
        JSON.parse(text)
        return false
    } catch (error) {
        return true
    }
}
